.grid-item-component {
    float: left;
    display: table;
    padding: 20px;

    .item-title {
        text-align: center;
        display: block;
        font-weight: bold;
        font-size: 1.5em;
        margin-bottom: 5px;
    }

}
