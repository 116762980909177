.sockets-settings {
  .socket-settings-item {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 10px;

    .socket-start-stop {
      width: 100%;

      .socket-switch {
        height: 90px;
        display: flex;
        flex-direction: row;
        align-items: center;

        span {
          font-size: 20px;
        }

        .toggle-wrapper {
          flex-grow: 1;
          display: flex;
          align-items: center;
        }

        .toggle-title {
          flex-grow: 1;
        }
      }
    }

    .socket-advanced {
      width: 100%;

      .socket-inputs {
        display: block;
        flex-wrap: wrap;
        flex-direction: row;
        padding: 3px 10px;

        .text-input {
            padding: 5px;
            width: 110px;

            input {
              padding: 12px 12px;
            }
        }
      }

      .light-modes-switch {
        display: flex;

        span {
          margin-left: 5px;
        }
      }

      .light-modes-list {
        margin-top: 10px;
        display: flex;
        flex-direction: column;

        .light-modes-row {
          display: flex;
          flex-direction: row;
          border-bottom: 1px solid #bcd0d9;

          span {
            padding: 4px;
            text-align: center;
            flex: 1;
          }

          .delete-light-mode {
            flex: none;
            width: 20px;
            visibility: hidden;
            cursor: pointer;
            color: red;
          }

          &:hover {
            .delete-light-mode {
              visibility: visible;
            }
          }
        }
      }

      .light-mode-input-wrapper {
        display: flex;
        flex-direction: row;
        margin-top: 10px;

        .select-wrapper {
          display: flex;
          flex-direction: column;
          width: 45px;
          margin-right: 10px;
          text-align: center;

          span {
            flex: 1;
          }

          .pwd-select {
            margin: 30px 0 8px 0;
            height: 42px;
            width: 42px;
            position: absolute;
          }
        }

        .pwd-button-component {
          display: flex;
          height: auto;
          align-items: center;
          margin-top: 21px;

          button {
            height: 45px;
            width: 45px;
          }
        }
      }
    }

    &:nth-child(even) {
      background: #2b3e50b0;
    }
  }
}