.header-title {
    cursor: pointer;
    font-weight: bold;
    line-height: 53px;
    margin-left: 20px;
    font-size: 1.5rem;
}

.login-button {
    color: inherit;
    font-weight: bold;
    text-decoration: none;
    float: right;
    line-height: 53px;
    margin-right: 20px;

    &:active {
        color: inherit;
    }
}
