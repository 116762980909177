.weather-forecast-day {
    display: flex;
    flex-grow: 1;
    flex-flow: column;
    align-items: center;
    padding: 10px;

    .day-title {
        font-size: 1.5rem;
        margin-bottom: -10px;
    }

    .day-image {
        max-width: 100px;
        min-width: 30px;

        img {
            width: 100%;
        }
    }

    .temperature-day {
        margin-top: -10px;
        font-size: 1.3rem;
    }

    .temperature-night {
        font-size: 1.3rem;
    }

    &:nth-child(odd) {
        background: #2b3e50b0;
    }
}
