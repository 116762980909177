.sensor-component {
    display: flex;
    flex-grow: 1;
    flex-direction: column;
    padding: 5px;
    padding-left: 10px;
    padding-right: 10px;
    min-width: 140px;

    .sensor-icon {
        margin: 0 auto;
        position: relative;
    
        .stale-icon {
            position: absolute;
            right: -5px;
            top: 15px;
        }    
    }


    .text-title {
        text-align: center;
        font-size: 1.5rem;
        font-weight: bold;
    }
    .text-value {
        text-align: center;
        font-size: 2rem;
    }
}