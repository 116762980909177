.App {
    background-color: #1d2731;
    display: flex;
    max-height: 100vh;
    min-height: 100vh;
    overflow: hidden;
    flex-direction: column;
    color: #bcd0d9;
    text-shadow: #1d2731 0.1em 0.1em 0.2em;

    .appMiddle {
        display: flex;
        flex: 1;
        max-height: 100%;
        overflow: auto;
    }

    .noselect {
        -webkit-touch-callout: none; /* iOS Safari */
        user-select: none; /* Safari */
        user-select: none; /* Konqueror HTML */
        user-select: none; /* Old versions of Firefox */
        user-select: none; /* Internet Explorer/Edge */
        user-select: none; /* Non-prefixed version, currently
                                        supported by Chrome, Edge, Opera and Firefox */
        img{
            -webkit-user-drag: none;
        }
    }

    input, textarea, button, select, a {
        -webkit-tap-highlight-color: rgb(0 0 0 / 0%);
    }

    .footer-text {
        width: 100%;
        display: block;
        line-height: 40px;
        text-align: center;
    }

    // pwojtaszko-design styles //
    .pwd-modal-component {
        background-color: #223343;
        color: inherit;
    }

    .pwd-dropdown-content {
        background-color: #1d2731;

        a {
            background-color: #1d2731;

            &:hover {
                background-color: #223343 !important;
            }
        }
    }
}
