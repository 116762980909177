.grid-component {
  width: 100%;

  .grid-item-component {
    min-height: 156px;
    background-color: #223343;
    margin: 10px;
    border-radius: 10px;
    -webkit-box-shadow: 0px 0px 30px 5px rgba(0,0,0,0.7); 
    box-shadow: 0px 0px 30px 5px rgba(0,0,0,0.7);
  }
}
