.weather-component {

    .weather-row {

        &.current {
            display: flex;
            flex-wrap: wrap;
            align-items: center;
            
            .weather-city {
                flex: 2;
                font-size: 2.5rem;
                text-align: center;
                float: left;
                padding-top: 10px;
                padding-bottom: 10px;
            }


            .weather-icon {
                width: 200px;
                flex: 2;
                
                img {
                    display: block;
                    margin: 0 auto;
                }
            }

            .weather-temperature {
                min-width: 150px;
                flex: 2;
                text-align: center;
                font-size: 3rem;
                font-weight: bold;
                float: left;
                padding-top: 10px;
                padding-bottom: 10px;

                .weather-desc {
                    display: block;
                    font-size: 1.5rem;
                    text-align: center;
                    width: 100%;    
                }
            }
        }

        &.forecast {
            display: flex;
            flex-wrap: wrap;
            align-items: stretch;
        }
    }

    .weather-hourly {
        display: flex;
        flex-flow: column;

        .values {
            display: flex;
            flex-wrap: wrap;
            flex: 1;
            margin-left: 40px;
            margin-right: 40px;
            align-items: center;

            .weather-icon {
                flex: 2;

                img {
                    width: 100%;
                    display: block;
                    margin: 0 auto;
                }
            }

            .weather-temperature {
                flex: 2;
                text-align: center;
                font-size: 3rem;
                font-weight: bold;
                float: left;

                div {
                    min-width: 170px;
                    display: flex;
                    flex-flow: row;

                    img {
                        width: 20px;
                        margin-bottom: 12px;
                        margin-right: 5px;
                        display: flex;
                        align-self: flex-end;
                    }
                }
            }

            .weather-other {
                display: flex;
                flex: 3;
                flex-flow: column;

                .other-value {
                    min-width: 190px;
                    text-align: center;
                    font-size: 2rem;
                    font-weight: bold;
                    display: flex;
                    flex-flow: row;

                    img {
                        margin-left: 15px;
                    }

                    span {
                        width: 100%;
                    }
                }
            }
        }

        .temperatures {
            display: flex;
            flex-flow: row;
            margin-left: 40px;
            margin-right: 40px;
            flex-wrap: wrap;
        }

        @media (max-width: 880px) {
            .pwd-line-chart .chart {
                padding: 0 0 20px 0;
            }

            .temperatures {
                margin-left: 0;
                margin-right: 0;
            }
        }
    }
}
