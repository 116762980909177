.weather-forecast-hour {
    display: flex;
    flex-grow: 1;
    flex-flow: column;
    text-align: center;
    
    img {
        width: 40px;
    }
}
