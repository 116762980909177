.Admin {
    padding: 15px;

    .header {
        padding-bottom: 20px;
        font-weight: bold;

        .header-text {
            font-size: 2rem;
        }
    }

    .settings {

        .setting-title {
            display: block;
            font-size: 1.5rem;
            padding: 10px;
            border: 1px dotted;
        }

        & > div {
            width: 100%;
        }
        
        .coordinates {
            display: flex;
            flex-direction: row;

            .text-input {
                width: 115px;
                padding: 5px;

                /* Chrome, Safari, Edge, Opera */
                input::-webkit-outer-spin-button,
                input::-webkit-inner-spin-button {
                    -webkit-appearance: none;
                    margin: 0;
                }

                /* Firefox */
                input[type=number] {
                    -moz-appearance: textfield;
                }
            }
        }

        .update-error {
            color: #fc6;
        }
    }

}
