.content-component {
    display: flex;
    flex: 1;
    min-height: 0px; /* IMPORTANT: you need this for non-chrome browsers */
    max-width: 100vw;
}

.content-box {
    display: flex;
    overflow: auto;
    width: 100%;
    padding-top: 10px;
    padding-bottom: 10px;
    padding-left: 10px;
    padding-right: 30px;
}

@media (max-width: 600px) {
    .content-box {
        padding: 0;
    }
}
