.socket-item {
  position: relative;
  padding: 5px 10px 15px 5px;
  text-align: center;
  margin: auto;

  .socket-disabled {
    position: absolute;
    width: 100%;
    height: 100%;
    z-index: 1;
    background-color: #223443;
    opacity: 0.8;
  }

  .socket-title {
    display: flex;
    font-size: 1.3rem;

    .socket-indicator {
      width: 20px;
      height: 20px;
      border-radius: 8px;
      background-color: dimgrey;
      border: 2px solid darkslategrey;
      margin-right: 10px;
      margin-bottom: 5px;

      &.enabled {
        background-color: green;
      }
    }
  }

  .socket-details {

    .socket-clock {
      width: 80px;
      height: 80px;
      position: relative;
      border-radius: 50%;
      margin: 0 auto;
      margin-top: 10px;

      .pie {
        border-radius: 50%;
        transform: rotate(-90deg);

        circle {
          fill: none;
          stroke-width: 32;
          r: 16;
          cx: 16;
          cy: 16;

          &.mode-0 {
            stroke: transparent;
          }

          &.mode-1 {
            stroke: #e5de44;
          }

          &.mode-2 {
            stroke: #ef810e;
          }

          &.mode-3 {
            stroke: #4f678d;
          }
        }

        &.standard {
          circle {
            &.mode-0 {
              stroke: transparent;
            }

            &.mode-1 {
              stroke: #139035;
            }
          }
        }
      }

      .clockface {
        position: absolute;
        top: 0;
        width: 100%;
        height: 100%;

        img {
          width: 100%;
        }
      }

      .clockface-arrow {
        position: absolute;
        top: 0;
        width: 100%;
        height: 100%;

        img {
          width: 100%;
        }
      }
    }
  }
}
