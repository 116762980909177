.room-section-component {
    flex-wrap: wrap;
    display: flex;
    flex-direction: row;
    align-items: center;

    .room-tile {
        display: flex;
        flex-grow: 1;
        flex-direction: column;
        padding: 5px;
        padding-left: 10px;
        padding-right: 10px;
        min-width: 140px;
        min-height: 105px;

        img {
            margin: 0 auto;
        }

        .text-title {
            text-align: center;
            font-size: 1.5rem;
            font-weight: bold;
        }
        .text-value {
            text-align: center;
            font-size: 2rem;
        }
    }
}
